<template>
  <div class="sign-in">
    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

      <a-col :span="24" :md="12" :lg="12" :xl="12" class="head-img">
        <img src="images/contact.png" alt="">
      </a-col>

      <a-col  :span="24" :md="12" :lg="12" :xl="12" style="padding:0 100px;margin: 50px 0">
        <div style="padding-left: 50px">
        <h5 style="color: #63b4ff">Contact Us</h5>
        <h2>联 系 我 们</h2>
        <div style="align-items: center;display: flex;margin-top: 15px">
          <svg t="1703669708439" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="25" height="25">
            <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024z" fill="#58a9e3" p-id="880"></path>
            <path d="M273.088 366.4s206.08 116.864 246.016 116.864c41.92 0 245.76-114.624 245.76-114.624l0.384-20.48a40.96 40.96 0 0 0-41.024-40.96H314.048a40.96 40.96 0 0 0-40.96 40.96v18.24z m491.776 63.68s-201.856 109.504-245.76 109.504c-40 0-245.76-109.44-245.76-109.504l-0.256 245.76c0 22.592 18.368 40.96 40.96 40.96h410.176a40.96 40.96 0 0 0 40.96-40.96l-0.32-245.76z" fill="#ffffff" p-id="881"></path>
          </svg>
          <span>service@copyapes.com</span>
        </div>
        <div style="align-items: center;display: flex;margin-top: 15px">
          <svg t="1703669911211" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="25" height="25">
            <path d="M1024 512c0 281.6-230.4 512-512 512S0 793.6 0 512 230.4 0 512 0s512 230.4 512 512z" fill="#58a9e3" p-id="880"></path>
            <path d="M697.14 767.18l100.556-489.062c10.07-41.199-15.087-61.78-45.26-51.507l-593.58 231.663c-40.277 15.463-40.277 41.2-5.051 51.473l150.937 51.507 357.172-231.663c15.087-10.308 30.173-5.154 20.138 5.12L395.264 602.453l-10.07 159.608c15.088 0 25.157-5.154 30.175-15.463l75.468-72.055L646.793 792.95c25.156 15.429 45.26 5.12 50.312-25.736z" fill="#ffffff" p-id="881"></path>
          </svg>
          <span>中文社区：<a href="https://t.me/copyapes_cn" target="_blank">https://t.me/copyapes_cn</a></span>
        </div>
        <div style="align-items: center;display: flex;margin-top: 15px">
          <svg t="1703669911211" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="25" height="25">
            <path d="M1024 512c0 281.6-230.4 512-512 512S0 793.6 0 512 230.4 0 512 0s512 230.4 512 512z" fill="#58a9e3" p-id="880"></path>
            <path d="M697.14 767.18l100.556-489.062c10.07-41.199-15.087-61.78-45.26-51.507l-593.58 231.663c-40.277 15.463-40.277 41.2-5.051 51.473l150.937 51.507 357.172-231.663c15.087-10.308 30.173-5.154 20.138 5.12L395.264 602.453l-10.07 159.608c15.088 0 25.157-5.154 30.175-15.463l75.468-72.055L646.793 792.95c25.156 15.429 45.26 5.12 50.312-25.736z" fill="#ffffff" p-id="881"></path>
          </svg>
          <span>客服：<a href="https://t.me/copyapes_admin" target="_blank">@copyapes_admin</a></span>
        </div>
        </div>
      </a-col>

    </a-row>
  </div>
</template>

<script>

export default {
}
</script>

<style scoped>
.sign-in .head-img img {
  width: 100%;
  display: block;
  padding: 30px;
  margin: 100px 0;
  /*transform: translateX(-100px);*/
}
span {
  font-size: 16px;
  margin-left: 10px;
}

</style>